import { Select, Switch } from 'antd'
import React, { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { resetDataForPermissionTable } from 'store/slices/dataPermissionsSlice';
import classes from './ExistingGroup.module.scss';
import { defaultGroups } from 'store/reducers/usersGroupCreator';

type ExistingGroupProps = {
  choosenGroup?: string | null;
  setChoosenGroup?: React.Dispatch<SetStateAction<string | null>>;
}

export default function ExistingGroup({choosenGroup, setChoosenGroup}: ExistingGroupProps) {
  const [isTemplateAvailable, setIsTemplateAvailable] = React.useState(false);

  const { groups, selectedGroup } = useAppSelector((state) => state.dataUsers);
  const { isLoadingPermissions } = useAppSelector((state) => state.permissions);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fullAdminId = groups.find(group => group.name === 'full_administrator')?.id;
  const currentGroups = groups.filter(group => group.id !== selectedGroup?.id)
    ?.map(group => ({
      value: group.id,
      label: defaultGroups.includes(group.name)
        ? t(`Users.table.${group.name}`)
        : group.name,
    }));

  const handleSwitchChange = () => {
    if (isTemplateAvailable) {
      console.log(isTemplateAvailable, 'if isTemplateAvailable');
      
      setChoosenGroup && setChoosenGroup(null);
      dispatch(resetDataForPermissionTable());
    } else {
      setChoosenGroup && setChoosenGroup(fullAdminId || null);

      console.log(isTemplateAvailable, 'else isTemplateAvailable');
    }
    setIsTemplateAvailable(!isTemplateAvailable);
  }

  return (
    <div className={classes.wrap}>
      <Switch
        disabled={isLoadingPermissions}
        className={classes.choosenArea}
        checked={isTemplateAvailable}
        onChange={handleSwitchChange}
      />
      {isTemplateAvailable
        ? <Select
            className={classes.select}
            popupMatchSelectWidth={true}
            placeholder={t('Documents.modals.permissions.permissionsGroup')}
            options={currentGroups}
            value={choosenGroup}
            onChange={setChoosenGroup}
            disabled={isLoadingPermissions}
          />
        : <span >{t('Documents.modals.permissions.copyPermissions')}</span>
      }
    </div>
  )
};
