import React from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next';
import colors from '../../scss/variables/colors.module.scss';

const TableLoader = (props: any) => { 
  
  const { t } = useTranslation();
  const marginTop = props.margintop || 20;
  return (
  <div style={{
    padding: props.padding || 15,
    overflow: 'hidden',
    backgroundColor: 'white',
    display: 'flex',
    height: props.height,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: props.borderradius || 0,
  }}>
    <ContentLoader 
      speed={2}
      width={'100%'}
      height={props.height || 600}
      title={t('Documents.table.loader')}
      // viewBox={`0 0 1440 ${props.height || 600}`}
      backgroundColor={colors.verylightgrey}
      {...props}
    >
      <rect x='0' y={marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={marginTop} rx='0' ry='0' width='170' height='20' />
      
      <rect x='0' y={20 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={20 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={20 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={20 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={20 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={20 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={20 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={20 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={20 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={20 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={60 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={60 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={60 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={60 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={60 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={60 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={60 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={60 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={60 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={60 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={100 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={100 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={100 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={100 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={100 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={100 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={100 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={100 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={100 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={100 + marginTop} rx='0' ry='0' width='170' height='20' />
      
      <rect x='0' y={140 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={140 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={140 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={140 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={140 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={140 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={140 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={140 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={140 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={140 + marginTop} rx='0' ry='0' width='170' height='20' />
      
      <rect x='0' y={180 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={180 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={180 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={180 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={180 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={180 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={180 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={180 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={180 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={180 + marginTop} rx='0' ry='0' width='170' height='20' />
      
      <rect x='0' y={220 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={220 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={220 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={220 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={220 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={220 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={220 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={220 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={220 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={220 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={260 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={260 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={260 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={260 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={260 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={260 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={260 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={260 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={260 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={260 + marginTop} rx='0' ry='0' width='170' height='20' />
      
      <rect x='0' y={300 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={300 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={300 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={300 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={300 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={300 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={300 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={300 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={300 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={300 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={340 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={340 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={340 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={340 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={340 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={340 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={340 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={340 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={340 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={340 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={380 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={380 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={380 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={380 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={380 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={380 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={380 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={380 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={380 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={380 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={420 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={420 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={420 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={420 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={420 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={420 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={420 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={420 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={420 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={420 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={460 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={460 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={460 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={460 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={460 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={460 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={460 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={460 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={460 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={460 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={500 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={500 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={500 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={500 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={500 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={500 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={500 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={500 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={500 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={500 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={540 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={540 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={540 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={540 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={540 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={540 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={540 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={540 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={540 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={540 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={580 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={580 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={580 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={580 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={580 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={580 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={580 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={580 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={580 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={580 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={620 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={620 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={620 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={620 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={620 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={620 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={620 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={620 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={620 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={620 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={660 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={660 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={660 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={660 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={660 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={660 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={660 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={660 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={660 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={660 + marginTop} rx='0' ry='0' width='170' height='20' />

      <rect x='0' y={700 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='180' y={700 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y={700 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='540' y={700 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y={700 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y={700 + marginTop} rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y={700 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y={700 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y={700 + marginTop} rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y={700 + marginTop} rx='0' ry='0' width='170' height='20' />

    </ContentLoader>
  </div>
)
}
export default TableLoader
