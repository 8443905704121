import { DeleteOutlined, FontColorsOutlined, SwapOutlined, InfoCircleOutlined, ShareAltOutlined, BranchesOutlined, FileAddOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import cn from 'classnames'
import { TFunction } from 'i18next'
import { IDocument, IFolder } from 'interfaces'
import React from 'react'
import classes from './ContextMenu.module.scss'
import { useAppSelector } from 'store/hook'
import { typesWithoutVersions } from 'components/DocumentTable/DocumentTable';

type ContextMenuProps = {
  entity: IDocument | IFolder;
  t: TFunction;
  deleteModalHandle: () => void;
  moveModalOpenHandle: () => void;
  renameModalHandle: () => void;
  shareModalHandle?: () => void;
  detailsModalHandle: (e: React.MouseEvent<HTMLElement>, tab?: string) => void;
  newVersionHandle?:  () => void;
};

export default function ContextMenu({
  entity, t,
  deleteModalHandle,
  moveModalOpenHandle,
  renameModalHandle,
  shareModalHandle,
  detailsModalHandle,
  newVersionHandle
}: ContextMenuProps) {
  const { currentFolder, folders } = useAppSelector(store => store.documents);
  const { userFoldersPermissions, userRootPermissions } = useAppSelector((store) => store.permissions);
  const { userPermissions } = useAppSelector(store => store.userData);
    
  const isRootFolder = !currentFolder[0];

  const targetFolderPermissions = (isRootFolder && userRootPermissions)
    ? userRootPermissions.permissions
    : userFoldersPermissions?.find(folder => folder.id === currentFolder[0]?.id)?.permissions;  
    
  const isEntityForbiddenToDelete = !entity.permissions?.includes('can_delete');
  const isEntityForbiddenToShare = !entity.permissions?.includes('can_sharing') || entity.is_confidential;
  const isAvailableMoveDocuments = targetFolderPermissions?.includes('can_delete');
  const isAvailableFoldersToMove = Boolean(currentFolder[0] || folders[0])
  const isAvailableEditingFolders = userPermissions.can_edit_folder_permissions;
  const isAvailableViewingVersions = !typesWithoutVersions.includes(entity.extension);
  const isAvailableUploadNewVersion = isAvailableViewingVersions && targetFolderPermissions?.includes('can_upload')
  
  return (
    <>
      {entity.type === 'folder' ?
        <div>
          <Button
            onClick={deleteModalHandle}
            icon={<DeleteOutlined />}
            className={classes.button}
            disabled={isEntityForbiddenToDelete}
          >
            {t('Documents.contextMenu.delete')}
          </Button>
          <Button
            icon={<FontColorsOutlined />}
            className={classes.button}
            onClick={renameModalHandle}
            disabled={!isAvailableEditingFolders}
          >
            {t('Documents.contextMenu.rename')}
          </Button>
        </div>
        : <div>
          {/* <Button
            disabled
            icon={<FontColorsOutlined />}
            className={classes.button}
          >
            {t('Documents.contextMenu.rename')}
          </Button> */}
          <Button
            onClick={moveModalOpenHandle}
            icon={<SwapOutlined />}
            className={cn(classes.button, classes.hovered)}
            disabled={!isAvailableMoveDocuments || !isAvailableFoldersToMove}
          >
            {t('Documents.contextMenu.move')}
          </Button>
          <Button
            onClick={deleteModalHandle}
            icon={<DeleteOutlined />}
            className={cn(classes.button, classes.hovered)}
            disabled={isEntityForbiddenToDelete}
          >
            {t('Documents.contextMenu.delete')}
          </Button>
          <Button
            onClick={shareModalHandle}
            icon={<ShareAltOutlined />}
            className={cn(classes.button, classes.hovered)}
            disabled={isEntityForbiddenToShare}
          >
            {t('Documents.contextMenu.sharing')}
          </Button>
          <Button
            onClick={(e) => detailsModalHandle!(e)}
            icon={<InfoCircleOutlined />}
            className={classes.button}
          >
            {t('Documents.contextMenu.details')}
          </Button>

          {isAvailableViewingVersions && <Button
            onClick={(e) => detailsModalHandle!(e, 'versions')}
            icon={<BranchesOutlined />}
            className={classes.button}
          >
            {t('Documents.contextMenu.versions')}
          </Button>}

          {isAvailableViewingVersions && !Boolean(entity.signing_status) && <Button
            onClick={newVersionHandle}
            icon={<FileAddOutlined />}
            className={classes.button}
            disabled={!isAvailableUploadNewVersion}
          >
            {t('Documents.contextMenu.addVersions')}
          </Button>}
        </div>
      }
    </>
  )
}
