import React from 'react';
import { Table, TableProps } from 'antd';
import { getlogsColumns } from './columns/logsColumns';
import classes from './LogsTable.module.scss';
import { IColumn, ILogs, IPreparedLogs } from 'interfaces';
import TableLoader from 'components/Sceletons/TableLoader';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { LogsDetailsModal } from 'components/Modals';
import { setIsLogsDetailsOpen } from 'store/slices/dataRoomSlice';
import { setChoosenLog, setLogsForPrint } from 'store/slices/dataLogsSlice';
import { getMockActions } from 'helpers/getMockActions';

type LogsTableProps = {
  logs: any[];
  isLoading: boolean;
  scroll: { x?: string | number, y?: string | number };
  height?: string;
  emptyHeight?: string;
}

export default function LogsTable({ logs, isLoading, scroll, height, emptyHeight } : LogsTableProps) {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);  

  const { isLogsDetailsOpen } = useAppSelector(store => store.dataRoom);

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const staticRuT = React.useMemo(() => i18n.getFixedT('ru'), []);
  const mockActions = React.useMemo(() => getMockActions(staticRuT, t), [i18n.language]);

  React.useEffect(()=> {
    setModifiedColumns(getActualFilters(logs));
  },[logs, i18n.language]);

  const renderCell = (props: any) => {
    const { value, info, col } = props;
    if ( col.key === 'details') {
      return <div
        className={classes.centredCell}
        onClick={() => openLogDetails(info)}
      >
        <EyeOutlined />
      </div>
    }

    if(col.key === 'entityname' && value === 'отсутствует'){
      const actionIdx = mockActions.ru.findIndex((action) => value === action);
      return mockActions.actual[actionIdx];
    }
    return value;
  };

  const openLogDetails = (log: ILogs) => {
    dispatch(setIsLogsDetailsOpen(true));
    dispatch(setChoosenLog(log))
  };

  const getActualFilters = (dataSource: any) => {
    const logsColumns = getlogsColumns(t);

    return logsColumns.map((col: IColumn) => {
      const unicValues = Array.from(new Set(dataSource.map((log: any) => log[col.dataIndex])))
      
      const currentFilters = unicValues.map((value: any) => {        
        if (col.key === 'action') {
          return { value: value, text: value }
        } else if (value === 'отсутствует') {
          return { value: 'отсутствует', text: t('Logs.none') }
        }
        return { value: value, text: value }
      });      
      return {
        ...col,
        render: (value: any, info: any) => renderCell({value, info, col}),
        filters: (col.dataIndex !== 'time' && col.dataIndex !== 'details') && currentFilters,
        onFilter: (value: boolean, record: any) => record[col.dataIndex] === value,
      }
    })
  }
  
  const onChange: TableProps<object>['onChange'] = (pagination, filters, sorter, extra) => {    
    if (extra.action === 'filter') {
      setModifiedColumns(getActualFilters(extra.currentDataSource));
      dispatch(setLogsForPrint(extra.currentDataSource as IPreparedLogs[]))
    };    
  };

  if (isLoading) {   
    return (
      <TableLoader height={height || 'calc(100vh - 212px)'}/>
    )
  }  

  return (
    <div className={classes.tableWrapper} style={{height: height || 'calc(100vh - 211px)'}}>
      <Table
        locale={{emptyText:
          <div style={{height: emptyHeight || 'calc(100vh - 262px)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {t('Logs.noLogs')}
          </div>
        }}
        columns={modifiedColumns}
        dataSource={logs}
        onChange={onChange}
        scroll={scroll}
        pagination={false}
        size='middle'
      />

      {isLogsDetailsOpen &&(
          <React.Suspense fallback={<div />}>
            <LogsDetailsModal
              open={isLogsDetailsOpen} 
            />
          </React.Suspense>
      )}
    </div>
  )
}
