import React from 'react'
import classes from '../ConfigureRoles.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { Input, Switch, Form, FormInstance, message, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { IDataUserTable, IPermission } from 'interfaces';
import api from 'api';
import { setGroupChoosen, setGroups, setUserAndData } from 'store/slices/dataUsersSlice';
import { defaultGroups } from 'store/reducers/usersGroupCreator';
import { useDebounce } from 'use-debounce';

type ConfigurePermissionsType = {
  form: FormInstance<any>;
  currentPermissions: string[];
  setCurrentPermissions: React.Dispatch<React.SetStateAction<string[]>>;
  configurableRole: IDataUserTable | null;
  setConfigurableRole: React.Dispatch<React.SetStateAction<IDataUserTable | null>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenPermissionsModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ConfigurePermissions({
  form,
  currentPermissions,
  setCurrentPermissions,
  configurableRole,
  setConfigurableRole,
  setIsLoading,
  setIsOpenPermissionsModal,
}: ConfigurePermissionsType) {
  const [displayingPermissions, setDisplayingPermissions] = React.useState<IPermission[]>([]);
  const [searchText, setSearchText] = React.useState<string>('');
  const [hideReset, setHideReset] = React.useState(true);

  const { rolesPermissions, isGroupLoading, groups, dataUserTable, users } = useAppSelector((state) => state.dataUsers);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const isDefaultGroup = configurableRole ? defaultGroups.includes(configurableRole?.name) : false;

  const [debouncedSearchText] = useDebounce(searchText, 300);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {    
    setDisplayingPermissions(rolesPermissions);
  },[rolesPermissions]);

  React.useEffect(() => {
    if (configurableRole?.id === 'new') {
      setDisplayingPermissions(rolesPermissions);
      setSearchText('')
    }
  },[configurableRole]);

  React.useEffect(() => {
    setHideReset(hideButton())
  },[currentPermissions]);

  React.useEffect(() => {
    if (debouncedSearchText) {
      const filteredPermissions = rolesPermissions.filter(permission => {
        const nameContainString = permission.name.toLocaleLowerCase().includes(debouncedSearchText.toLocaleLowerCase());
        const descriptionContainString = permission.description.toLocaleLowerCase().includes(debouncedSearchText.toLocaleLowerCase())
        return descriptionContainString || nameContainString;
      })
      setDisplayingPermissions(filteredPermissions);
    } else {
      setDisplayingPermissions(rolesPermissions);
    }
  }, [debouncedSearchText])

  const onChange = (checked: boolean, _: any, permissionType: string) => {
    const newPermissions = checked
      ? [...currentPermissions, permissionType]
      : currentPermissions.filter(permission => permission !== permissionType);
    setCurrentPermissions(newPermissions);
  };

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const newRole = await api.createRole({
        name: values.role_name,
        room_id: dataRoom?.id,
        permissions: currentPermissions,
      })

      const newGroup: IDataUserTable = {
        name: newRole.data.name,
        group_id: newRole.data.groups![0].id,
        id: newRole.data.groups![0].id,
        key: newRole.data.groups![0].id,
        first_name: `${newRole.data.groups![0].name} (0)`,
        type: 'group',
        emailOrRole: newRole.data.name,
        role: {
          id: newRole.data.id!,
          name: newRole.data.name,
          permissions: currentPermissions,
        },
        children: [],
      };

      dispatch(setGroupChoosen(newGroup));
      
      setConfigurableRole(newGroup);
      form.resetFields();
      dispatch(setGroups([...groups, newGroup]));
      dispatch(setUserAndData({ dataUserTable: [...dataUserTable, newGroup], users: users }));
      message.success(`${t('Users.modals.configureRoles.successCreateNewRole')} '${newRole.data.name}'`, 5);
      message.warning(t('Users.modals.configureRoles.newRolePermissions'), 100)
      setIsOpenPermissionsModal(true);
    } catch (e: any) {      
      message.error(t('Users.modals.configureRoles.errorCreateNewRole'))
    } finally {
      setIsLoading(false);
    }
  };  

  const hideButton = () => {
    const sortedRolePermissions = configurableRole?.role?.permissions && [...configurableRole?.role?.permissions].sort().join(',');
    const isTheSamePerissions = [...currentPermissions].sort().join(',') === sortedRolePermissions;
    return (isDefaultGroup || isTheSamePerissions)
      ? true
      : false
  };

  return (
    <div className={classes.configureSection}>
      { currentPermissions && !isGroupLoading && Boolean(configurableRole?.id)
        ? (<>
          <div className={classes.inputsWrap}>
            {
              configurableRole?.id === 'new'
                ? (
                  <Form onFinish={onSubmit} form={form}>
                    <Form.Item
                      name='role_name' 
                      rules={[{ required: true, message: t('Users.modals.configureRoles.enterRoleName') }]}
                    >
                      <Input placeholder={t('Users.modals.configureRoles.nameNewRole')}/>
                    </Form.Item>
                  </Form>
                )                
                : (
                  <div className={classes.input}>
                    <Input
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder={t('Users.modals.configureRoles.searchPermission')}
                      suffix={<SearchOutlined style={{color: 'gray'}}/>}
                      allowClear
                    />
                  </div>
                )
            }
          </div>
          <div className={classes.permissionsWrap}>
            <div className={classes.configureTitle}>
              <div>{t('Users.modals.configureRoles.configure')}</div>
              <Button
                onClick={() => setCurrentPermissions(configurableRole?.role?.permissions)}
                className={hideReset ? classes.hideButton : classes.resetButton}
                type='link'
              >
                {t('Users.modals.configureRoles.reset')}
              </Button>
            </div>
            {displayingPermissions[0]
              ?  displayingPermissions.map(el => (
                <div className={classes.permissionItem} key={el.id}>
                  <div>
                    <div className={classes.permissionTitle}>{t(`Users.modals.permissions.titles.${el.type}`)}</div>
                    <div className={classes.permissionDescription}>{t(`Users.modals.permissions.descriptions.${el.type}`)}</div>
                  </div>
                  <Switch
                    disabled={configurableRole ? isDefaultGroup : false}
                    checked={currentPermissions?.includes(el.type)}
                    onChange={(checked, event) => onChange(checked, event, el.type)}
                  />
                </div>))
              : <div className={classes.noFound}>{t(`Users.modals.configureRoles.noFound`)}</div>
            }
          </div>
        </>)
        : (
          <div className={classes.chooseSomeGroup}>
            <div>{t('Users.modals.configureRoles.chooseRole')}</div>
          </div>
        )
      }
    </div>
  )
}
