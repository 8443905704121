import { Form, Input, InputRef, Modal, message } from 'antd';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import classes from './NewRoomModal.module.scss';
import dayjs from 'dayjs';
import api from 'api';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { adminPermissions, setDefaultUserPermissions, setUserAndRoomStatus, setUserData } from 'store/slices/userDataSlice';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { createChat } from 'store/reducers/chatCreator';
import { Tarrifs } from 'components/TariffsComponent/tariffsData';
import { fetchUserPermissions } from 'store/reducers/userDataCreator';
import { ChoosePayment } from 'components/TariffsComponent/TariffsComponent';

type NewRoomModalProps = {
  open: boolean
  onCancel: () => void;
  newRoomState?: ChoosePayment;
};

export default function NewRoomModal({open, onCancel, newRoomState}: NewRoomModalProps) {
  const [isCreatingRoom, setIsCreatingRoom] = React.useState(false);
  const [form] = Form.useForm();
  const nameInput = React.useRef<InputRef | null>(null);
      
  const { userData } = useAppSelector(store => store.userData);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCreateNewRoom = async (value: any) => {
    setIsCreatingRoom(true);
    try {
      const formData = new FormData();

      for(const key in value){
        value[key] && formData.append(key, value[key]);
      }
      if (newRoomState?.tariff) {     
        const tariffData:any = newRoomState?.tariff;   
        for(const key in tariffData){
          tariffData[key] && formData.append(key, key === 'tariff_constraints'
            ? JSON.stringify(tariffData[key])
            : tariffData[key]
          );
        }
      };

      const { data: newRoom } = newRoomState?.tariff?.tariff_name === Tarrifs.Trial 
        ? await api.createTrialRoom(value.name)
        : await api.createRoom(formData)      

      const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;
      const parsedDateRoom = {...newRoom, last_action_timestamp: UTCDate, created_at: Date.parse(String(newRoom.created_at)), permissions: adminPermissions}
      userData && dispatch(setUserData({...userData, available_rooms: userData?.available_rooms 
        ? [parsedDateRoom, ...userData?.available_rooms] 
        : [parsedDateRoom]
      }));
      if (parsedDateRoom.payment_link) {
        window.location.href = parsedDateRoom.payment_link;
      };

      navigate(`/room/${newRoom.id}/documents`);
      await dispatch(fetchUserPermissions({ idRoom: newRoom.id }));
      dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
      dispatch(setDefaultUserPermissions());
      dispatch(setDataRoom(newRoom));
      dispatch(
        createChat({
          data: {
            entity_type: 1,
            entity_id: newRoom.id,
            room_id: newRoom.id!,
            title: newRoom.name,
            members_ids: [userData?.id!],
          },
          ownerId: userData?.id!,
        })
      );
      setIsCreatingRoom(false);
      onCancel();
      message.success(t('Header.success.created'));
    } catch (err: unknown) {
      setIsCreatingRoom(false);
      const details = (err instanceof AxiosError && err?.response?.data?.error) || newRoomState?.tariff?.tariff_name

      switch(details) {
        case 'room already exists':
          return message.warning(t('Header.error.alreadyExists'));
        case 'trial':
          return message.error(t('Header.error.trialCreate'));
        default: 
          return message.error(t('Header.error.create'));
      };
    }
  };

  React.useEffect(() => nameInput.current?.focus(), []);

  const modalTitle = (value?: Tarrifs) => {    
    switch(value) {
      case Tarrifs.Trial:
        return t('Header.createModal.trial_title'); 
      case Tarrifs.Starter:
        return `${t(`Tariffs.tariff`)} ${t(`Tariffs.tariff_name.${value}`)} ${newRoomState?.tariff?.tariff_constraints.storage} ${t('Tariffs.gb')}`;
      case Tarrifs.Business:
        return `${t(`Tariffs.tariff`)} ${t(`Tariffs.tariff_name.${value}`)} ${newRoomState?.tariff?.tariff_constraints.storage} ${t('Tariffs.gb')}`;
      default: 
        return t('Header.createModal.title');
    };
  }

  const roomNameLabel = (value?: Tarrifs) => {
    switch(value) {
      case Tarrifs.Trial:
        return t('Header.createModal.trial_nameLabel');
      default: 
        return t('Header.createModal.nameLabel');
    };
  };

  return (
    <Modal 
      styles={{ body: {maxHeight: '85vh'} }}
      width={'60%'}
      title={modalTitle(newRoomState?.tariff?.tariff_name)}
      open={open}
      centered={true}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t('Header.createModal.createButton')}
      confirmLoading={isCreatingRoom}
      cancelText={t('Header.createModal.cancelButton')}
      className={classes.modalAddRoom}
    >
      <div className={classes.modalWrap}>
      <Form className={classes.form} layout='vertical' onFinish={handleCreateNewRoom} form={form}>
        
        <Form.Item className={classes.form_input} label={roomNameLabel(newRoomState?.tariff?.tariff_name)} name='name' rules={[{ required: true, message: t('Header.createModal.roomNameValidate') }]}>
          <Input
            ref={nameInput}
            count={{
              show: true,
              max: 80,
            }}
            maxLength={80}
            placeholder={t('Header.createModal.namepPaceholder')}
          />
        </Form.Item>

        {/* <Form.Item className={classes.form_input} label={t('Header.createModal.descriptionLabel')} name='description'>
            <TextArea
              autoSize={{minRows: 4, maxRows: 5}}
              count={{
                show: true,
                max: 500,
                exceedFormatter: (txt, { max }) => txt.split('').slice(0, max).join(''),
              }}
            />
         </Form.Item> */}
      </Form>
      </div>
    </Modal>
  );
}
