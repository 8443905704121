import { Badge, Button, Popover, Table, Tooltip } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import React from 'react'
import classes from './DocumentTable.module.scss'
import { getDocumentColumns } from './columns/documentColumns'
import { IColumn, IDocument, IFolder } from 'interfaces'
import { PushpinOutlined, LockOutlined, CheckCircleOutlined, UnlockOutlined, InfoOutlined, MessageOutlined, CloseCircleOutlined, FileAddOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setChoosenDocuments, setCurrentFolder, setSelectedDocument, setSelectedFolder } from 'store/slices/dataDocumentsSlice';
import TableLoader from '../Sceletons/TableLoader';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import { setMoreInfoHidden, setIsOpenDeleteEntityModal, setIsOpenFileDetailsModal, setIsOpenMoveEntityModal, setIsOpenRenameEntityModal, setIsUploadingDocuments, setIsOpenShareEntityModal, setMovingDocument, setSelectedKeys, setIsOpenNewVersionModal } from 'store/slices/windowStateSlice';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { setIsOpenViewer } from 'store/slices/viewerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFeather, faHourglass } from '@fortawesome/free-solid-svg-icons';
import ContextMenu from '../ContextMenu/ContextMenu';
import { availableIconFormat, documnentIcons } from '../../helpers/documentIcons';
import Spinner from 'components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { createChat } from 'store/reducers/chatCreator';
import { setDefaultActiveKey } from 'store/slices/dataAgreementsSlice';
import { capitalizeFirstLetterOfString } from 'helpers/stringCaseManager'
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import { sortItems } from 'helpers/sortTableItems'
import { widthOfContextMenu } from 'i18t';

type recordType = IDocument | IFolder;

const agreementIcons = {
  0: <FontAwesomeIcon style={{ color: 'orange' }} icon={faHourglass} />,
  1: <CloseCircleOutlined style={{ color: 'red' }} />,
  2: <CheckCircleOutlined style={{ color: 'green' }} />,
  null: <FileAddOutlined />,
};

const signingIcons = {
  ...agreementIcons,
  null: <FontAwesomeIcon style={{ color: 'grey' }} icon={faFeather} />,
};

export const typesToSigning = ['docx', 'doc', 'pdf', 'odt'];
export const typesWithoutVersions = ['folder'];

type TableProps = {
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  isLoadingRooms: boolean;
  handleDropDocuments: (selectedFiles: string[], folder_id: string[]) => void;
  onConfidentModalOpen: () => void;
  selectedRowIds: string[];
  setSelectedRowIds: React.Dispatch<React.SetStateAction<string[]>>;
  emptyText: string;
  openContextModal: (entity: recordType, openTargetModal: () => void) => void;
  resetContextMenuField: () => void;
  openContextMenu: (event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement>, entity: recordType) => void;
}

export default function DocumentTable({
  selectedRowKeys,
  selectedRowIds,
  setSelectedRowIds,
  setSelectedRowKeys,
  isLoadingRooms,
  handleDropDocuments,
  onConfidentModalOpen,
  emptyText,
  openContextModal,
  resetContextMenuField,
  openContextMenu
}: TableProps) {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const [modifiedData, setMofifiedData] = React.useState<(IDocument | IFolder)[]>([])
  const [delayClickTimer, setDelayClickTimer] = React.useState<NodeJS.Timeout>();

  const { dataDocumentTable, documents, folders, isLoadingDocuments, currentFolder, selectedDocument, selectedFolder} = useAppSelector(store => store.documents);
  const { isLoadingProcess, isDeletingDocuments, isDownloadingDocuments, isUploadingDocuments } = useAppSelector((store) => store.windowState);
  const { userPermissions, isLoadingUserPermissions, userData } = useAppSelector((store) => store.userData);
  const { userFoldersPermissions } = useAppSelector((store) => store.permissions);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const privateRooms = ['85141b41-bc64-4623-b293-2ba4bbea0e6f', '143d9fd4-96b7-46e8-819f-9515a04eaff9']
  const referenceOrder = [
      'Введение.pdf',
      'Введение - курс "5 Шагов к Грин Карте"',
      'Список стран участвующих и не участвующих в лотерее.pdf',
      'Часть 1.pdf',
      'Часть 1. Курс "5 Шагов к Грин Карте"',
      'Часть 2.pdf',
      'Часть 2 курса "5 Шагов к Грин Карте"',
      'Часть 3.pdf',
      'Часть 3 курса "5 Шагов к Грин Карте"',
      'Часть 4.pdf',
      'Часть 4. Курс "5 Шагов к Грин Карте"',
      'Часть 5.pdf',
      'Часть 5. Видео Инструкции. Курс "5 Шагов к Грин Карте"',
      'Распространенные ошибки.pdf',
      'Часто задаваемые вопросы .pdf'
  ]

  const showDetails = (entity: any, e?: React.MouseEvent<HTMLElement>,) => {
    if(entity?.status === 0) return
    e?.stopPropagation();
    if (entity.type === 'folder') {
      dispatch(setSelectedFolder(entity));
      dispatch(setSelectedDocument(null));
    } else {
      dispatch(setSelectedDocument(entity));
      dispatch(setSelectedFolder(null));
    }
    dispatch(setIsOpenFileDetailsModal(true));
  };

  const detailsModalHandle = (info: any, e?: React.MouseEvent<HTMLElement>, tab?: string) => {
    openContextModal(info, () => showDetails(info, e));
    tab && dispatch(setDefaultActiveKey(tab));
  };

  React.useEffect(()=> {
    const documentColumns = getDocumentColumns(t);
    const customColumns = documentColumns?.map((col: IColumn) => ({
      ...col,
      render: (value: any, info: any) => col.key === 'name'
        ? <Popover
            overlayInnerStyle={{
              padding: 0,
              width: widthOfContextMenu[(localStorage.getItem('language') || 'en') as keyof typeof widthOfContextMenu],
              // position: 'absolute', left: info.pageX - 200,
            }}
            arrow={false}
            placement='bottomLeft'
            open={info.isOpenContextMenu}
            content={
              <ContextMenu
                key={info.key}
                // onMouseLeave={resetContextMenuField}
                entity={info}
                t={t}
                deleteModalHandle={() => openContextModal(info, () => dispatch(setIsOpenDeleteEntityModal(true)))}
                moveModalOpenHandle={() => openContextModal(info, () => dispatch(setIsOpenMoveEntityModal(true)))}
                renameModalHandle={() => openContextModal(info, () => dispatch(setIsOpenRenameEntityModal(true)))}
                shareModalHandle={() => openContextModal(info, () => dispatch(setIsOpenShareEntityModal(true)))}
                detailsModalHandle={(e:React.MouseEvent<HTMLElement> ,tab?: string) => detailsModalHandle(info, e, tab)}
                newVersionHandle={() => openContextModal(info, () => dispatch(setIsOpenNewVersionModal(true)))}
              />
            }
            trigger='contextMenu'
          > 
            {renderCell({value, info, col})}
          </Popover>
        : renderCell({value, info, col}),
    }))
    setModifiedColumns(customColumns);
    const sortedItems = privateRooms.includes(dataRoom?.id!) ? sortItems(dataDocumentTable, referenceOrder) : dataDocumentTable
    setMofifiedData(sortedItems)
  },[documents, i18n.language, folders, selectedDocument, selectedFolder, dataDocumentTable]);

  const onSectionIconClick = (info: any, section: string) => {    
    if(info?.status === 0) return;
    dispatch(setSelectedDocument(info));
    dispatch(setDefaultActiveKey(section));
    dispatch(setIsOpenFileDetailsModal(true));
  };

  const renderCell = (props: any) => {
    const { value, info, col } = props;
    const icon = availableIconFormat.includes(info.type) ? info.type : 'default';
    const isConfidential = info?.is_confidential;

    if(col.key === 'upload_at'){      
      return value && getDateWithTimeZone(value, userData?.date_format!);
    }
    
    if(col.key === 'permissions'){
      const currentPermissions = info.permissions?.map((permission: string)=> {
        const localized = t(`Logs.logsModal.${permission}`);
        return localized;
      });
            
      if(info.type !== 'folder' && info.permissions) {
        return <Tooltip
          title={
            <ul>
              {currentPermissions.map((permission: string, index: number) => <li key={`permission_tooltip_item_${index}`}>{`- ${capitalizeFirstLetterOfString(permission)}`}</li>)}
            </ul>
          }
          overlayStyle={{ minWidth: 200, maxWidth: 400 }}
        >
          <LockOutlined />
          </Tooltip>
      }
      else return null;
    }

    if(col.key === 'agreement'){      
      if(info.type === 'folder' || !userPermissions.can_edit_agreement) return;
      return (
        <div className={classes.scaleHover} onClick={() => onSectionIconClick(info, 'document_agreement')}>
          {agreementIcons[info.agreement_status as keyof typeof agreementIcons]}
        </div>
      );
    }


    if(col.key === 'signing_status'){            
      if(!typesToSigning.includes(info.type) || !userPermissions.can_manage_signing) return;
      return (
        <div className={cn(classes.textToCenter, classes.scaleHover)} onClick={() => onSectionIconClick(info, 'document_signing')}>
          {signingIcons[info.signing_status as keyof typeof signingIcons]}
        </div>
      );
    }

    if(col.key === 'name'){
      if (info.status === 0) {
        return (
          <div className={classes.rowWrap} style={{ justifyContent: 'space-between' }}>
            <div className={classes.icon} style={{display: 'flex'}}>
              {documnentIcons[icon as keyof typeof documnentIcons]} 
            </div>
            {value}
            <Spinner />
          </div>
        );
      } else {
        return <div className={classes.rowWrap}>
          <div className={classes.icon} style={{display: 'flex', alignItems:'center'}}>
            {documnentIcons[icon as keyof typeof documnentIcons]}
            {isConfidential && <div className={cn(classes.confidentialIcon, 'confidential')}>
              <Tooltip title={t('Documents.table.confidential')}> <UnlockOutlined style={{color: 'red'}}/> </Tooltip>
            </div>}
          </div>
          <div className={classes.blockName}>
            <div>{value}</div>
            {!typesWithoutVersions.includes(info.type) && info?.version_count > 1 && (
              <div
                className={classes.versionCnt}
                onClick={(e) => detailsModalHandle(info, e, 'versions')}
                // onClick={() => dispatch(setIsOpenNewVersionModal(true))}
              >
                { `${t('Documents.table.version_count')}: ${info?.version_count - 1}` }
              </div>
            )}
          </div>
        </div>;
      };
    }

    if(col.key === 'notes' && info.has_notes){
      return (
        <div
          className={cn(classes.textToCenter, classes.scaleHover)}
          onClick={() => onSectionIconClick(info, 'documents_notes')}
        >
          <PushpinOutlined />
        </div>
      );
    }

    if(col.key === 'chats' && info.type !== 'folder'){
      const onClickChat = async (e: React.MouseEvent<HTMLDivElement>) => {
        if(info?.status === 0) return
        dispatch(setIsUploadingDocuments(true));
        const chatInfo = await dispatch(
          createChat({
            data: {
              entity_type: 0,
              entity_id: info.id,
              members_ids: dataRoom?.available_users?.map(({ id }) => id)!,
              title: info.name,
              room_id: dataRoom?.id!,
            },
            ownerId: userData?.id!,
          })
        );
        dispatch(setSelectedKeys(['chat']));
        dispatch(setIsUploadingDocuments(false));
        navigate(`/room/${dataRoom?.custom_url || dataRoom?.id}/chat/document/${(chatInfo.payload as any).chat.id}`);
      };
      return (
        <Badge dot={Boolean(info.unviewed_amount)} count={info.unviewed_amount}>
          <div onClick={onClickChat} className={cn(classes.scaleHover, classes.textToCenter)}>
            <MessageOutlined />
          </div>
        </Badge>
      );
    }

    if (col.key === 'details') {
      if(info.type === 'folder') return;
      return (
        <Tooltip
          title={t('Documents.table.details')}
        >
            <Button
              className={classes.detailIcon}
              size='small'
              icon={<InfoOutlined />}
              onClick={(e) => showDetails(info, e)}
              onDoubleClick={(e) => e.stopPropagation()}
            />
        </Tooltip>
      )
    }
   
    return value;
  };
  
  const onSelectRowChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {    
    const withTokens = selectedRows.filter(({ status }) => status !== 0);
    const documentsKeys = withTokens.map(({ key }) =>  key);
    const documentsKeysAndPermissions = withTokens.map(({ key, permissions }) => ({ key, permissions}));
    const selectedFilesIds = selectedRows.filter(({type}) => type !== 'folder').map(({ id }: any) => id);
    setSelectedRowIds(selectedFilesIds);
    dispatch(setChoosenDocuments(documentsKeysAndPermissions));
    setSelectedRowKeys(documentsKeys);
  };  

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectRowChange,
    // checkStrictly: false,
  };

  const addDragClass = (target: any) => {
    const element = target as HTMLElement;
    element.classList.add(classes.onDrag);
  }

  const removeDragClass = (target: any) => {
    const element = target as HTMLElement;
    element.classList.remove(classes.onDrag);
  }

  const handleDragStart = (event: React.DragEvent<HTMLTableRowElement>, record: recordType) => {
    dispatch(setMovingDocument(true));
    addDragClass(event.target);
    event.dataTransfer.items.add(JSON.stringify(record), 'application/json');
  }

  const handleDragLeave = (event: React.DragEvent<HTMLTableRowElement>) => {
    removeDragClass(event.target);
  }

  const handleDragEnd = (event: React.DragEvent<HTMLTableRowElement>) => {
    dispatch(setMovingDocument(false));
    removeDragClass(event.target);
  }

  const handleDragOver = (event: React.DragEvent<HTMLTableRowElement>) => {
    dispatch(setMovingDocument(true));
    event.preventDefault();
    addDragClass(event.target);
  }

  const handleDrop = (event: React.DragEvent<HTMLTableRowElement>, record: recordType) => {
    dispatch(setMovingDocument(false));
    event.preventDefault();
    removeDragClass(event.target);

    const data = event.dataTransfer.items;
    data[0]?.getAsString((s) => {
      const droppedFile = JSON.parse(s) as recordType;

      const isDrop = selectedRowIds.includes(droppedFile.id!)
      const includedFiles = isDrop ? selectedRowIds : [droppedFile.id!];
      handleDropDocuments(includedFiles, [record.id!]);
    });
  };

  const onRowClick = (file: IDocument) => {     
    // if(file.status === 0) return; 
    // if (!isActiveContextMenu) {
      
    //   dispatch(setChoosenDocuments([{ key: file.key!, permissions: file.permissions! }]));
    //   setSelectedRowKeys([file.key!]);
    //   if (file.type === 'folder') {
    //     dispatch(setSelectedFolder(file));
    //     dispatch(setSelectedDocument(null));
    //   } else {
    //     dispatch(setSelectedDocument(file));
    //     dispatch(setSelectedFolder(null));
    //     setSelectedRowIds([file.id]);
    //   }
    // }
  }

  if (isLoadingDocuments || isLoadingUserPermissions || isLoadingRooms || !userFoldersPermissions) {   
    return (
      <TableLoader height={'calc(100vh - 261px)'}/>
    )
  }  

  return (
    
    <div
      className={cn(classes.tableArea,
        (isLoadingProcess || isDeletingDocuments || isUploadingDocuments || isDownloadingDocuments) ? classes.tableRowLoading : 'empty')
      }
    >
      <Table
        locale={{emptyText:
          <div style={{height:'calc(100vh - 375px)', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 18}}>
            {t(`Documents.table.${emptyText}`)}
          </div>
          }}
        className={classes.table}
        dataSource={modifiedData!}
        columns={modifiedColumns}
        scroll={{
          y: 'calc(100vh - 340px)',
          x: '625px'
        }}
        pagination={false}
        size='middle'
        expandable={{
          expandIcon: () => null
        }}
        rowSelection={rowSelection}
        rowClassName={(record) => {
          return isLoadingProcess ||
            isDeletingDocuments ||
            isUploadingDocuments ||
            isDownloadingDocuments ||
            record.status === 0
            ? classes.tableRowLoading
            : classes.tableRow;
        }}
        onRow={(record, idx: any) => {
          const onViewClick = async (event: any) => {
            if(record.status === 0) return; 
            if (record.type === 'youtube') {
              // @ts-ignore
              window.open(record.description, '_blank')
              event.stopPropagation();
              clearTimeout(delayClickTimer);
              return
            }
            if (record.type === 'folder') {
              
              clearTimeout(delayClickTimer);
              dispatch(setSelectedDocument(null));
              dispatch(setMoreInfoHidden(true));
              dispatch(setCurrentFolder([...currentFolder, record]));
              navigate(`/room/${dataRoom?.id}/documents/${record.id}`)
              localStorage.setItem('previousFolderPath', JSON.stringify([...currentFolder, record]))
              return;
            }
            event.stopPropagation();
            clearTimeout(delayClickTimer);
            // dispatch(setSelectedDocument(record));

            if(record.confident_document?.length){              
              onConfidentModalOpen();
              return;
            }

            navigate(`${window.location.pathname}?webviewer_file_id=${record.id}`);
            dispatch(setIsOpenViewer(true));
            await dispatch(fetchDocumentToViewer({
              token: record.token,
              extension: record.extension,
              is_agreement: false,
              id: dataRoom?.id,
              entity: record.id,
              action: record.is_confidential ? 'view_confident': 'view',
              locale: i18n.language
            }));
          }

          const onDelayRowClick = () => {
            clearInterval(delayClickTimer);
            setDelayClickTimer(setTimeout(() => {
              onRowClick(record);
            }, 250))
          };

          const onDropHandle = (event: any) => handleDrop(event, record);
          return{
            onDrop: record.type === 'folder' ? onDropHandle : undefined,
            onDragLeave: handleDragLeave,
            onDragStart: (event: any) => handleDragStart(event, record),
            onDragEnd: handleDragEnd,
            onDragOver:  record.type === 'folder' ? handleDragOver : undefined,
            onClick: onDelayRowClick,
            onDoubleClick: onViewClick,
            onContextMenu:(e) => openContextMenu(e, record),        
            onMouseLeave: resetContextMenuField,    
            draggable: record.type !== 'folder',
          }
        }}
      />
    </div>
  )
};

