import { MessengerWithTabs } from 'components/Messanger';
import MessangerLoader from 'components/Sceletons/MessangerLoader';
import dayjs from 'dayjs';
import { Suspense, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import fetchChatData from 'store/reducers/chatCreator';
import { setChatMessage, setChatStatus, setMessageForReply } from 'store/slices/chatsSlice';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import classes from './Chat.module.scss';
import utc from 'dayjs/plugin/utc';
import WebViewer from 'components/Webviewer/WebViewer';
import { setIsOpenViewer } from 'store/slices/viewerSlice';
import { useNavigate } from 'react-router-dom';

dayjs.extend(utc);

const Chat = () => {
  const { isLoadedSession, roomChatLoading, selectedChat, messageForReply } = useAppSelector((state) => state.chat);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userData } = useAppSelector((state) => state.userData);
  const { documentUrl, isDocumentError, isDocumentLoading, isOpenViewer } = useAppSelector((state) => state.viewer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSelectedKeys(['chat']));
  }, []);

  const onSendMessage = (message: string) => {
    if (!message || !message.trim()) return;
    const id = dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
    dispatch(
      setChatMessage({
        message: message,
        id,
        status: 'pending',
        replied_to: messageForReply ? messageForReply : null,
      })
    );
    dispatch(
      fetchChatData({
        data: {
          status: 'pending',
          message: message,
          created_at: id,
          timestamp: id,
          email: userData?.email!,
          user_name: `${userData?.first_name} ${userData?.last_name}`,
          room_id: dataRoom?.id!,
          chat_id: selectedChat?.id!,
          replied_to: messageForReply ? messageForReply : null,
        },
        type: 'add',
      })
    );
    dispatch(setChatStatus('send'));
    dispatch(setMessageForReply(null));
  };

  const onWebViewClose = () => {
    navigate(window.location.pathname);
    dispatch(setIsOpenViewer(false))
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.mainSpace}>
          {dataRoom?.id && isLoadedSession && !roomChatLoading ? (
            <MessengerWithTabs onSendMessage={onSendMessage} />
          ) : (
            <MessangerLoader />
          )}
        </div>
      </div>

      {isOpenViewer && (
        <Suspense fallback={<div />}>
          <WebViewer
            errorMessage={isDocumentError}
            onClose={onWebViewClose}
            isLoading={isDocumentLoading}
            url={documentUrl}
          />
        </Suspense>
      )}
    </>
  );
};

export default Chat;
