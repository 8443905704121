import { TFunction } from 'i18next';
import classes from '../VersionsTab.module.scss';

export const getVersionsColumns = (t: TFunction) => [
  {
    title: t('Documents.details.versions.tableColumns.name'),
    dataIndex: 'name',
    key: 'name',
    // sorter: (a: IDocument, b: IDocument) => a.name.localeCompare(b.name),
    width: '27%',
  },
  {
    title: t('Documents.details.versions.tableColumns.date'),
    dataIndex: 'upload_at',
    key: 'upload_at',
    width: '18%',
  },
  {
    title: t('Documents.details.versions.tableColumns.author'),
    dataIndex: 'author',
    key: 'author',
    width: '15%',
  },
  {
    title: t('Documents.details.versions.tableColumns.comment'),
    dataIndex: 'version_comment',
    key: 'version_comment',
    // ellipsis: true,
    width: '30%',
  },
  {
    title: '',
    dataIndex: 'details',
    key: 'details',
    width: '10%',
    className: classes.textToCenter,
  },
];
