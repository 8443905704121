import classes from '../Messanger.module.scss';

export const INPUT_TAG = {
  OPEN: `<span class="${classes.inputTag}">`,
  CLOSE: '</span>',
}

export const CURRENT_INPUT_TAG = {
    OPEN: `<span class="${classes.inputTag}" data-current="true">`,
    CLOSE: '</span>',
  }

export const  MESSAGE_TAG = {
  OPEN: '[link]',
  CLOSE: '[/link]',
}
