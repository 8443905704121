import { FC, ChangeEvent, useEffect } from 'react';
import { Card, Form, Input } from 'antd';
import { TFunction } from "i18next";
import { useAppSelector } from 'store/hook';
import cn from 'classnames';
import classes from '../RoomSettings.module.scss';

type RoomDataType = {
  setSettings: (event: ChangeEvent) => void;
  t: TFunction;
};

const RoomData: FC<RoomDataType> = ({ setSettings, t }) => {
  const [form] = Form.useForm();  

  const { dataRoom } = useAppSelector((state) => state.dataRoom);

  const setFormData = () => {
    form.setFieldsValue({
      name: dataRoom?.name,
      description: dataRoom?.description || '',
    });
  };

  useEffect(() => {
    setFormData()
  }, [dataRoom])  

  return (
    <Card title={t('Settings.title')} bordered className={cn(classes.card, classes.settingsTitle)}>
      <Form layout='vertical' className={classes.forms_data} form={form}>
        <Form.Item
          rules={[
            { required: true },
          ]}
          name='name'
          label={t('Settings.room.labels.name')}
        >
          <Input
            placeholder={t('Settings.room.placeholders.name')}
            name='name'
            count={{
              show: true,
              max: 80,
            }}
            maxLength={80}
            onChange={setSettings}
          />
        </Form.Item>
        {/* <Form.Item
          name="description"
          label={t('Settings.room.labels.description')}
        >
          <Input.TextArea
            rows={6}
            placeholder={t('Settings.room.placeholders.description')}
            name="description"
            onChange={setSettings}
          />
        </Form.Item> */}
      </Form>
    </Card>
  );
};

export default RoomData;
