import React from 'react';
import { Modal } from 'antd';
import classes from './ConfirmationModal.module.scss';
import { useTranslation } from 'react-i18next';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';

type ConfirmationModalPops = {
  isOpen: boolean;
  onCancel: () => void;
  isLoading: boolean;
  submit: () => void;
  text: string | JSX.Element;
  title: string;
  okText?: string;
  cancelText?: string;
  customClassname?: string
};

const ConfirmationModal = ({ isOpen, onCancel, isLoading, submit, text, title, okText, cancelText, customClassname }: ConfirmationModalPops) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      centered
      okText={okText || t('Documents.management.delete')}
      cancelText={t('Documents.management.cancel')}
      confirmLoading={isLoading}
      title={title}
      onOk={submit}
      className={classes.modalDelete}
    >
      <div className={customClassname ? classes[customClassname] : classes.delete}>
        {text}
      </div>

      {isLoading && <UploadingComponent />}
    </Modal>
  );
};

export default ConfirmationModal;
