import { TFunction } from 'i18next';
import { IFolder, IDocument } from 'interfaces';

const createPermissionTableData = (
  dataDocumentTable: (IFolder | IDocument)[],
  t: TFunction,
  rootFolderForPermissions?: any,
): IFolder[] => {
  const filesWithoutFolder = dataDocumentTable.filter((doc) => doc.type !== 'folder') as IDocument[];
  const rootFolderOfTheRoom = (t: TFunction): IFolder => ({
    ...rootFolderForPermissions,
    name: t('Documents.modals.permissions.rootFiles'),
    id: null,
    key: 'rootFolder',
    type: 'rootfolder',
    permissions:
      rootFolderForPermissions && rootFolderForPermissions.permissions 
      ? rootFolderForPermissions.permissions
      : [],
    children: filesWithoutFolder[0] 
      ? filesWithoutFolder.map(doc => (
        {
          ...doc,
          permissions: rootFolderForPermissions
            ? rootFolderForPermissions?.permissions
              ? rootFolderForPermissions?.permissions
              : []
            : []
        }
      ))
      : null,
  });
  
  return [
    rootFolderOfTheRoom(t),
    ...dataDocumentTable
      // .filter((doc) => doc.id !== '0')
      // .filter((doc) => doc.type === 'folder')
      .map((folder) => ({
        ...folder,
        permissions:
          folder.permissions 
            ? folder.permissions
            : [],
        children: folder.children
          ? folder.children?.map((document) => ({
              ...document,
              permissions:
                folder.permissions 
                  ? folder.permissions
                  : [],
            }))
          : null,
      })),
  ];
};

export const allPermissions = [
  'can_sharing',
  'can_view',
  'can_download',
  'can_delete',
  'can_download_pdf',
  'can_upload'
];

export const createPermissionForGroup = (
  dataDocumentTable: (IFolder | IDocument)[],
  t: TFunction,
  choosenRole?: string
): IFolder[] => {
  const rootFolderOfTheRoom = (t: TFunction): IFolder => ({
    name: t('Documents.modals.permissions.rootFiles'),
    id: null,
    key: 'rootFolder',
    type: 'rootfolder',
    permissions: choosenRole === 'full_administrator' ? allPermissions : [],
  });

  return [
    rootFolderOfTheRoom(t),
    ...dataDocumentTable
      .map((folder) => ({
        ...folder,
        type: 'folder',
        key: folder.id || 'null',
        permissions: choosenRole === 'full_administrator' ? allPermissions : [],
      })),
  ];
};

export default createPermissionTableData;
