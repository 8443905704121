import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import { IDataUserTable, IGroup, ILogs, IPendingInviteUsers, IPermission, IRestrictedUser, IUsers } from "interfaces";
import fetchGroupsAndUsers, { fetchGlobalUsers, fetchOnlyGroups, fetchPermissions, fetchUserLogs, setNewRole, usersTableWithNewUsersRole } from "store/reducers/usersGroupCreator";

type UsersInitialState = {
  users: IUsers[];
  globalUsers: IRestrictedUser[];
  groups: IGroup[];

  isGroupLoading: boolean;
  isGroupError: string;
  
  selectedUser: IUsers | null;
  selectedGroup: IGroup | null;
  dataUserTable: (IUsers | IDataUserTable)[];
  isEmptyGroupResponce: boolean;

  userLogs: ILogs[];
  isLoadingUserLogs: boolean;
  errorUserLogs: string;
  pendingInviteUsers: IPendingInviteUsers[];

  isConfigureRolesOpen: boolean;
  rolesPermissions: IPermission[];
  rolesPermissionsError: string,
  isRolesPermissionsLoading: boolean;
};

const initialState: UsersInitialState = {
  users: [],
  globalUsers: [],
  groups: [],
  pendingInviteUsers: [],
  isGroupError: "",
  isGroupLoading: false,

  selectedUser: null,
  selectedGroup: null,
  dataUserTable: [],
  isEmptyGroupResponce: false,

  userLogs: [],
  isLoadingUserLogs: false,
  errorUserLogs: 'string',

  isConfigureRolesOpen: false,
  rolesPermissions: [],
  rolesPermissionsError: '',
  isRolesPermissionsLoading: false
};

const dataUsersSlice = createSlice({
  name: "dataUsers",
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<IGroup[]>) => {
      state.groups = action.payload;
    },
    setUserChoosen: (state, action: PayloadAction<IUsers | null>) => {      
      state.selectedUser = action.payload;
      state.selectedGroup = null;
    },
    setGroupChoosen: (state, action: PayloadAction<IGroup | null>) => {
      state.selectedGroup = action.payload;
      state.selectedUser = null;
    },
    setUserAndData: (state, action: PayloadAction<{users: IUsers[], dataUserTable: (IUsers | IDataUserTable)[], groups?: IGroup[]}>) => {
      state.users = action.payload.users;
      state.dataUserTable = action.payload.dataUserTable;
      if (action.payload.groups) {
        state.groups = action.payload.groups;
      }
    },
    setIsEmptyGroupResponce: (state, action: PayloadAction<boolean>) => {
      state.isEmptyGroupResponce = action.payload;
    },
    setIsConfigureRolesOpen: (state, action: PayloadAction<boolean>) => {
      state.isConfigureRolesOpen = action.payload;
    },
    setPendingInviteUsers: (state, action: PayloadAction<IPendingInviteUsers[]>) => {
      state.pendingInviteUsers = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchGroupsAndUsers.pending, (state) => {
      state.isGroupLoading = true;
      state.isGroupError = "";
    });

    builder.addCase(fetchGroupsAndUsers.rejected, (state, action: PayloadAction<any>) => {
      state.isGroupLoading = false;
      state.isGroupError = action.payload;
    });

    builder.addCase(fetchGroupsAndUsers.fulfilled, (state, action: PayloadAction<{users: IUsers[], groups: IGroup[], pendingInviteUsers: IPendingInviteUsers[], dataUserTable: (IUsers | IDataUserTable)[]}>) => {
      state.users = action.payload.users;
      state.groups = action.payload.groups;
      state.dataUserTable = action.payload.dataUserTable;
      state.pendingInviteUsers = action.payload.pendingInviteUsers;
      state.isGroupLoading = false;
      state.isGroupError = "";
    });
    
    builder.addCase(fetchOnlyGroups.pending, (state) => {
      state.isGroupLoading = true;
      state.isGroupError = "";
    });

    builder.addCase(fetchOnlyGroups.rejected, (state, action: PayloadAction<any>) => {
      state.isGroupLoading = false;
      state.isGroupError = action.payload;
    });

    builder.addCase(fetchOnlyGroups.fulfilled, (state, action: PayloadAction<IGroup[]>) => {
      state.groups = action.payload;
      state.isEmptyGroupResponce = action.payload[0] ? false : true;
      state.isGroupLoading = false;
      state.isGroupError = "";
    });

    builder.addCase(fetchUserLogs.fulfilled, (state, action: PayloadAction<{ userLogs: ILogs[] }>) => {
      state.isLoadingUserLogs = false;
      state.errorUserLogs = '';
      state.userLogs = action.payload.userLogs;
    })
    builder.addCase(fetchUserLogs.pending, (state) => {
      state.isLoadingUserLogs = true;
    })
    builder.addCase(fetchUserLogs.rejected, (state, action: any) => {
      state.isLoadingUserLogs = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.errorUserLogs = action.payload.errorMessage
      } else {
        state.errorUserLogs = action.error.message
      }
    });

    builder.addCase(fetchPermissions.pending, (state) => {
      state.isRolesPermissionsLoading = true;
      state.rolesPermissionsError = "";
    });

    builder.addCase(fetchPermissions.rejected, (state, action: PayloadAction<any>) => {
      state.isRolesPermissionsLoading = false;
      state.rolesPermissionsError = action.payload;
    });

    builder.addCase(fetchPermissions.fulfilled, (state, action: PayloadAction<IPermission[]>) => {
      state.rolesPermissions = action.payload;
      state.isRolesPermissionsLoading = false;
      state.rolesPermissionsError = "";
    });

    builder.addCase(fetchGlobalUsers.pending, (state) => {
      state.isGroupLoading = true;
      state.isGroupError = "";
    });

    builder.addCase(fetchGlobalUsers.rejected, (state, action: PayloadAction<any>) => {
      state.isGroupLoading = false;
      state.isGroupError = action.payload;
    });

    builder.addCase(fetchGlobalUsers.fulfilled, (state, action: PayloadAction<IRestrictedUser[]>) => {
      state.globalUsers = action.payload;
      state.isGroupLoading = false;
      state.isGroupError = "";
    });

    builder.addCase(setNewRole.fulfilled, (state, action: PayloadAction<{user: IUsers, group_id: string, t: TFunction}>) => {
      state.dataUserTable = usersTableWithNewUsersRole(
        state.dataUserTable,
        state.users,
        action.payload.user,
        action.payload.group_id,
        action.payload.t
      )
      state.users = state.users.map((someUser) =>
        someUser.email === action.payload.user?.email ? { ...someUser, group: [action.payload.group_id] } : someUser
      );
    });
  },
});

const dataUsersReducer = dataUsersSlice.reducer;

export const {
  setGroups,
  setUserChoosen,
  setGroupChoosen,
  setUserAndData,
  setIsEmptyGroupResponce,
  setIsConfigureRolesOpen,
  setPendingInviteUsers,
} = dataUsersSlice.actions;
export default dataUsersReducer;
