import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { RootState } from 'store';
import { IDocument, IFolder } from 'interfaces';
import { fetchUserPermissions } from './userDataCreator';
import { setUserAndRoomStatus } from 'store/slices/userDataSlice';
import { setCurrentFolder, setDocuments, setSelectedDocument } from 'store/slices/dataDocumentsSlice';
import { typesToSigning } from 'components/DocumentTable/DocumentTable';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { setFoldersForPermissions } from 'store/slices/dataPermissionsSlice';

type uploadPayload = {
  type: 'add' | 'replace';
  newFolder?: IFolder;
  newDocuments?: IDocument[];
  parent_folder_id?: string;
};

type deletePayload = {
  document_id: string;
  folder_id?: string;
  room_id?: string;
  prev_version?: string;
};

type deleteFolderPayload = {
  folder_id: string;
  room_id: string;
};

type moveDocumentPayload = {
  folder_id: string;
  room_id: string;
  document_id: string;
  older_folder_id: string;
};

type updatePermissionsType = {
  room_id: string;
  folder_id: string;
  new_permissions: string[];
  group_id: string;
  author: string;
};

const add = (newElements: (IFolder | IDocument)[], source: (IFolder | IDocument)[], parentFolder?: any) => {
  const availableIds = source.map(doc => doc.id);
  const matchingIds: (string | null | undefined)[] = [];
  
  const newElementsWithoutDuplicates = newElements?.filter(document => {
    if (availableIds.includes(document.id)) matchingIds.push(document.id);
    return !availableIds.includes(document.id);
  }).map(doc => ({
    ...doc,
    status: 0,
    has_notes: false,
    confident_document: [],
    is_agreed: null,
    signing_status: typesToSigning.includes(doc.extension || '') ? null : '',
    permissions: parentFolder.permissions,
    clicked: false,
    key: doc.id,
    agreement_status: null,
  })) as IDocument[];  

  const sourceElementsWithRightPermissions = !matchingIds[0]
    ? source
    : source.map(document => (!matchingIds.includes(document.id)
      ? document
      : {
        ...document,
        status: 1,
        permissions: parentFolder.permissions,
      })
    );
  
  return [...newElementsWithoutDuplicates, ...sourceElementsWithRightPermissions];
};

const replace = (
  newElement: IDocument[],
  source: (IDocument | IFolder)[],
  permissions?: any,
  parentFolder?: string | null
) => {
  const isHasDocument = Boolean(source.find(({ id }) => id === newElement[0].id));
  return parentFolder === newElement[0].folder_id && !isHasDocument
    ? [...source, ...newElement]
    : source.map((sourceDocument) =>
        sourceDocument.id === newElement[0].id
          ? {
              ...sourceDocument,
              key: newElement[0].token,
              upload_at: newElement[0].upload_at,
              is_confidential: newElement[0].is_confidential,
              status: newElement[0].status,
              token: newElement[0].token,
              is_signed: newElement[0].is_signed,
              permissions: permissions?.permissions,
              version_count: newElement[0].version_count,
            }
          : sourceDocument
      );
};

const statusCases = {
  add,
  replace,
};

const fetchStatusDocuments = createAsyncThunk(
  'documents/fetchStatus',
  async ({ type, newDocuments, newFolder, parent_folder_id }: uploadPayload, thunkApi) => {
    const { dispatch } = thunkApi;
    const rootState = thunkApi.getState() as RootState;
    const { userFoldersPermissions, userRootPermissions, foldersForPermissions } = rootState.permissions;
    const { dataRoom } = rootState.dataRoom;
    const { currentFolder, documents: sourceDocuments, folders: sourceFolders } = rootState.documents;
    const folderPath = currentFolder[currentFolder.length - 1]?.id || null;

    const parentFolderID = currentFolder[0] ? currentFolder[0]?.id : null;
    const currentFolderWithPermissions = parentFolderID
      ? userFoldersPermissions?.find((folder) => folder.id === parentFolderID)
      : userRootPermissions;

    if (newDocuments) {
      const documents = statusCases[type](
        newDocuments,
        sourceDocuments,
        currentFolderWithPermissions,
        folderPath
      ) as IDocument[];
      
      dispatch(setDocuments({ documents, folders: sourceFolders }));
    }

    if (newFolder) {
      const folders = sourceFolders.map(folder => folder.id).includes(newFolder.id) 
        ? sourceFolders
        : [...sourceFolders, newFolder];      
      dispatch(setDocuments({ documents: sourceDocuments, folders }));
      dispatch(setDataRoom({...dataRoom!, folders }));      
      folderPath === null && dispatch(setFoldersForPermissions([...foldersForPermissions, newFolder]));
    }
  }
);

const fetchDeleteDocument = createAsyncThunk('document/delete', async (payload: deletePayload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { documents, currentFolder, folders } = state.documents;
    const folderPath = currentFolder.length - 1;

    if ((!currentFolder[folderPath] && !payload.folder_id) || payload.folder_id === currentFolder[folderPath].id) {
      const withoutDeletedDocuments = documents.filter((doc) => doc.id !== payload.document_id);
      dispatch(setDocuments({ documents: withoutDeletedDocuments, folders }));
      return withoutDeletedDocuments;
    }

    return null;
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to delete document');
  }
});

const fetchDeleteFolder = createAsyncThunk('folder/delete', async (payload: deleteFolderPayload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { documents, folders } = state.documents;
    const { dataRoom } = state.dataRoom;
    const { foldersForPermissions } = state.permissions;

    dispatch(setDocuments({ documents, folders: folders.filter(({ id }) => id !== payload.folder_id) }));
    dispatch(setDataRoom({...dataRoom!, folders: folders.filter(({ id }) => id !== payload.folder_id) }));
    dispatch(setFoldersForPermissions(foldersForPermissions.filter(({ id }) => id !== payload.folder_id)));

    return null;
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to delete document');
  }
});

const fetchDocumentUpdate = createAsyncThunk('document/update', async (payload: deletePayload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { documents, folders, selectedDocument, dataDocumentTable } = state.documents;
    const documentPermissions = dataDocumentTable.find(({ id }) => payload.document_id)?.permissions!;
    
    const response = await api.getDocumentById(payload.document_id);

    /// functionality fo document_replace
    const updating_document_id = payload.prev_version ? payload.prev_version : payload.document_id;

    dispatch(
      setDocuments({
        documents: documents.map((el) =>
          el.id === updating_document_id ? { ...response.data, permissions: documentPermissions } : el
        ),
        folders,
      })
    );

    selectedDocument?.id === response.data.id && dispatch(setSelectedDocument(response.data));
    return null;
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to delete document');
  }
});

const updateDocumentSign = createAsyncThunk('document/update', async (payload: IDocument, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { documents, folders, selectedDocument, dataDocumentTable } = state.documents;
    const documentPermissions = dataDocumentTable.find(({ id }) => payload.id)?.permissions!;    
    dispatch(
      setDocuments({
        documents: documents.map((el) =>
          el.id === payload.id ? { ...payload, permissions: documentPermissions } : el
        ),
        folders,
      })
    );

    selectedDocument?.id === payload.id && dispatch(setSelectedDocument(payload));
    return null;
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to update document');
  }
});

const fetchDocumentMove = createAsyncThunk('document/move', async (payload: moveDocumentPayload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { documents, folders, currentFolder } = state.documents;
    const folderPath = currentFolder[currentFolder.length - 1].id || null;

    if (payload.folder_id === folderPath) {
      const response = await api.getDocumentById(payload.document_id);
      dispatch(setDocuments({ documents: [...documents, response.data], folders }));
      return null;
    }

    return null;
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to moved document');
  }
});

const fetchFoldersPermissionsUpdate = createAsyncThunk(
  'permissions/fetchUpdate',
  async (payload: updatePermissionsType, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const { dispatch } = thunkApi;
      const { currentFolder } = state.documents;
      const { userData } = state.userData;
      const { dataRoom } = state.dataRoom;

      if (userData?.email === payload.author) return null;

      if (payload.folder_id === null || currentFolder.find(({ id }) => id === payload.folder_id)) {
        localStorage.removeItem('previousFolderPath');
        dispatch(setCurrentFolder([]));
        dispatch(fetchUserPermissions({ idRoom: dataRoom?.id! }));
        dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
        return null;
      }

      return null;
    } catch (err) {
      return thunkApi.rejectWithValue('Failed to update permissions');
    }
  }
);

export default fetchStatusDocuments;
export {
  fetchDeleteDocument,
  fetchDeleteFolder,
  fetchDocumentUpdate,
  updateDocumentSign,
  fetchDocumentMove,
  fetchFoldersPermissionsUpdate,
};
