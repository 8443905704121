import { requestTemplate } from 'api';
import { IDocument, ILogs, INotes } from 'interfaces';

//VERSIONS
const getDocumentVersions = (document_id: string) =>
  requestTemplate.get<IDocument[]>(`/documents/version/?last_version_id=${document_id}`);

const uploadDocumentVersion = (
  payload: { formData: FormData}, // id: string, name: string, action: string },
) => requestTemplate.post<IDocument>(`/documents/version/`, payload.formData, {
   headers: { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' }
});

const versionReset = (payload: {version_id: string, comment?: string}) => requestTemplate.post(`/documents/version/reset/?version_id=${payload.version_id}&comment=${payload.comment || ''}`, payload);

const versionRestore = (payload: {version_id: string}) => requestTemplate.post(`/documents/version/restore/?version_id=${payload.version_id}`);

const getLogsOfVersions = (
  payload: { document_id: string, locale: string}
)=> requestTemplate.get<ILogs[]>(`/logs/version/?document_id=${payload.document_id}&locale=${payload.locale}`);

const getNotesOfVersions = (
  payload: { document_id: string }
) => requestTemplate.get<INotes[]>(`/notes/version/?document_id=${payload.document_id}`);

const versions = {
  getDocumentVersions,
  uploadDocumentVersion,
  versionReset,
  versionRestore,
  getLogsOfVersions,
  getNotesOfVersions,
};

export default versions;
