
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import prepareLogs from 'helpers/prepareLogs';

interface IDocumentLogsThunk {
  document_id: string;
  locale: string;
  versions?: number | null | undefined;
}

export const fetchAvailableDocumentsLogs = createAsyncThunk(
  'documentsLogs/fetchAll',
  async (payload: IDocumentLogsThunk, thunkApi) => {
    try {
      const response = (payload.versions && payload.versions > 1)
        ? await api.getLogsOfVersions(payload)
        : await api.getDocumentLogs(payload);

      const logs = response.data;
      
      return {
        logs: prepareLogs(logs),
        dampLogs: logs
      };
    } catch (err) {
      return thunkApi.rejectWithValue('Download Failed');
    }
  }
);
