import { CheckOutlined, CloseCircleOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { faHourglass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, CollapseProps } from 'antd';
import RowLoader from 'components/Sceletons/RowLoader';
import { IPendingInviteUsers } from 'interfaces';
import { memo } from 'react';
import classes from './Invitations.module.scss';
import { TFunction } from 'i18next';

type Props = {
  users: IPendingInviteUsers[];
  isLaoding: boolean;
  deleteInvite: (invite_id: string) => void;
  t: TFunction;
};

const inviteStatusIcons = [
  <FontAwesomeIcon className={classes.glassWaiting} icon={faHourglass} />,
  <CheckOutlined className={classes.pendingInvite_accept} />,
  <CloseOutlined className={classes.pendingInvite_decline} />,
  <LoadingOutlined />,
];

function PendingInvitations({ users, isLaoding, deleteInvite, t }: Props) {
  const items: CollapseProps['items'] = [
    {
      key: 'pending-users-1',
      label: t('Users.Tabs.waitingList'),
      children: users.map((user) => (
        <div key={`user-${user.id}`}>
          {inviteStatusIcons[user.invite_status]} {user.email}{' '}
          <CloseCircleOutlined
            className={classes.pendingInvite_close}
            onClick={() => user.invite_status !== 3 && deleteInvite(user.id)}
          />
        </div>
      )),
    },
  ];
  return isLaoding ? (
    <RowLoader width={600} padding={'0 0 0 0'} height={70} />
  ) : (
    <Collapse className={classes.pendingInvite} items={items} />
  );
}

export default memo(PendingInvitations);
