import { Modal } from 'antd';
import { RcFile } from 'antd/es/upload';
import prepareDocuments from 'helpers/prepareDocuments';
import { TFunction } from 'i18next';
import { IDocument } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsUploadingDocuments } from 'store/slices/windowStateSlice';
import classes from './Postfix.module.scss';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';

type Props = {
  isOpen: boolean;
  duplicateDocuments: {
    forUpload: RcFile[];
    forDelete: IDocument[];
  };
  deleteChoosenDocuments: (documents: any[], documentList: IDocument[]) => void;
  handleUpload: (fileList?: RcFile[]) => void;
  onCancel: () => void;
  t: TFunction;
};

export default function PostfixModal({
  isOpen,
  duplicateDocuments,
  t,
  onCancel,
  handleUpload,
  deleteChoosenDocuments,
}: Props) {
  const dispatch = useAppDispatch();
  const { isUploadingDocuments } = useAppSelector((state) => state.windowState);

  const onUploadClick = async () => {
    dispatch(setIsUploadingDocuments(true));
    await handleUpload(duplicateDocuments.forUpload);
    onCancel();
    dispatch(setIsUploadingDocuments(false));
  };

  const onReplaceClick = async () => {
    dispatch(setIsUploadingDocuments(true));
    const preparedDocumentKeys = prepareDocuments(duplicateDocuments.forDelete, []).map(({ key }) => ({ key }));
    await deleteChoosenDocuments(preparedDocumentKeys, duplicateDocuments.forDelete);
    await handleUpload(duplicateDocuments.forUpload);
    onCancel();
    dispatch(setIsUploadingDocuments(false));
  };

  return (
    <>
      <Modal
        title={t('Documents.modals.postfix.title')}
        onCancel={onReplaceClick}
        open={isOpen}
        cancelText={t('Documents.modals.postfix.replaceButton')}
        okText={t('Documents.modals.postfix.uploadButton')}
        onOk={onUploadClick}
        onClose={onCancel}
        classNames={{ body: classes.body }}
      >
        {t('Documents.modals.postfix.body')} {duplicateDocuments.forDelete.map(({ name }) => name).join(', ')}

        {isUploadingDocuments && <UploadingComponent />}
      </Modal>

    </>
  );
}
