import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import PDFViewer from 'components/Webviewer/Viewer/PDFViewer';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import { useTranslation } from 'react-i18next';
import RowLoader from 'components/Sceletons/RowLoader';
import SignatureRulesModal from './SigningComponents/SignatureRulesModal';
import SigningCard from './SigningCard/SigningCard';
import NotPartisipant from './SigningComponents/NotPartisipant';
import CheckboxWithRules from './SigningComponents/CheckboxWithRules';
import SigningCertificate from './SigningComponents/SigningCertificate';
import classes from './SingingTab.module.scss';
import SignedEarlier from './SigningComponents/SignedEarlier';
import { message } from 'antd';
import { resetSigningState } from 'store/slices/signingSlice';
import { updateDocumentSign } from 'store/reducers/documentsSocketsCreator';
import api from 'api';

type SingingTabProps = {
  closeDetailsModal: () => void;
};

const SingingTab = ({closeDetailsModal}: SingingTabProps) => {
  const [isRulesModalOpen, setIsRulesModalOpen] = React.useState<boolean>(false);
  const [isSectionAvailable, setIsSectionAvailable] = React.useState<boolean>(true);
  const [displayingCertificatSigning, setDisplayingCertificatSigning] = React.useState<boolean>(false);

  const { selectedDocument } = useAppSelector((state) => state.documents);
  const { documentUrl, isDocumentLoading } = useAppSelector((state) => state.viewer);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userData } = useAppSelector((state) => state.userData);
  const { signing, isSigningLoading, needSigningFromUsers, signingInfo } = useAppSelector((state) => state.signing);

  const needSigningIdsParticipants = needSigningFromUsers.filter(user => !user.signing_status).map(user => user.user_id);
  const idsParticipants = needSigningFromUsers.map(user => user.user_id);  
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (signing[0]) {
      setIsSectionAvailable(needSigningIdsParticipants.includes(userData?.id!) && signingInfo?.status !== 1);
      setDisplayingCertificatSigning(idsParticipants.includes(userData?.id!) || userData?.id === signingInfo?.initiator_id);
    };

    return () => {
      setIsSectionAvailable(true);
    }
  }, [signing]);

  React.useEffect(() => {
    setTimeout(() => !userData?.has_sign && setIsRulesModalOpen(true), 1000);
  }, [userData]);

  React.useEffect(() => {
    selectedDocument && dispatch(
      fetchDocumentToViewer({
        token: selectedDocument.token,
        id: dataRoom?.id!,
        entity: selectedDocument.id,
        extension: selectedDocument.type,
        is_agreement: false,
        action: 'view',
        locale: i18n.language,
      })
    );
  }, [selectedDocument?.id]);

  const openRulesModal = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setIsRulesModalOpen(true);
  };

  const deleteSigning = async () => {
    try {
      await api.deleteSigning({document_id: selectedDocument?.id!});
      dispatch(resetSigningState());
      dispatch(updateDocumentSign({...selectedDocument!, signing_status: null, status: 0}));
      message.success(t('Documents.details.signing.successDeleteSigning'));
      closeDetailsModal();
    } catch (e) {
      message.error(t('Documents.details.signing.errorDeleteSigning'));
    }
  };

  const signedEarlier = selectedDocument?.is_signed && selectedDocument?.signing_status === null;

  const displaySignSidebar = React.useCallback((signedEarlier: Boolean) => {
    switch(signedEarlier) {
      case true:
        return <SignedEarlier />;
      default: 
        return (
          isDocumentLoading || isSigningLoading
            ? <RowLoader height={400} width={400} padding={'0 0 0 0'}/>
            : <SigningCard closeDetailsModal={closeDetailsModal} deleteSigning={deleteSigning}/>
        );
    };
  },[selectedDocument, isDocumentLoading, isSigningLoading]);

  return (
    <div>
      {isSectionAvailable
        ? (
          <div className={classes.container}>
            <div className={classes.viewerWrap}>
              {isDocumentLoading || isSigningLoading 
                ? <RowLoader height={1200} width={1000} padding={'0 12px 0 0'}/>
                : <PDFViewer url={documentUrl} isModal={false} defaultScale={1}/>
              }
            </div>

            <div className={classes.sidebar}>
              
              { displaySignSidebar(Boolean(signedEarlier)) }

              <CheckboxWithRules
                openRulesModal={openRulesModal}
                hasSign={userData?.has_sign}
              />
            </div>
          </div>
        )
        : (
          <div>{
            displayingCertificatSigning
            ? <SigningCertificate deleteSigning={deleteSigning}/>
            : <NotPartisipant />
          }</div>
        )
      }

      {isRulesModalOpen && (
        <React.Suspense fallback={<div />}>
          <SignatureRulesModal
            isRulesModalOpen={isRulesModalOpen}
            setIsRulesModalOpen={setIsRulesModalOpen}
            isAlreadyAccept={Boolean(userData?.has_sign)}
          />
        </React.Suspense>
      )}
    </div>
  );
}

export default SingingTab;
