import React from 'react'
import classes from './ConfigureRoles.module.scss';
import { Form, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import GroupsList from './components/GroupsList';
import { IDataUserTable } from 'interfaces';
import ConfigurePermissions from './components/ConfigurePermissions';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { defaultGroups } from 'store/reducers/usersGroupCreator';
import api from 'api';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setGroups, setUserAndData } from 'store/slices/dataUsersSlice';

type ConfigureRolesType = {
  isOpen: boolean;
  onClose: () => void;
  configurableRole: IDataUserTable | null;
  setConfigurableRole: React.Dispatch<React.SetStateAction<IDataUserTable | null>>;
  setIsOpenPermissionsModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ConfigureRoles({ isOpen, onClose, configurableRole, setConfigurableRole, setIsOpenPermissionsModal }: ConfigureRolesType) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentPermissions, setCurrentPermissions] = React.useState<string[]>([]);

  const { dataUserTable, users, groups } = useAppSelector((state) => state.dataUsers);

  const isDefaultGroup = configurableRole && defaultGroups.includes(configurableRole?.name);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    configurableRole?.id === 'new' && form.validateFields();

    if  (configurableRole?.role?.permissions[0]) {
      const regExp = /'|\[|\]|\n| /g;
      
      const reducePermissions = configurableRole?.role?.permissions.reduce((acc: string[], currnet: string) => {
        const currentPermissions = currnet.replace(regExp, '').split(',');
        return [...acc, ...currentPermissions]
      }, []);
                
      setCurrentPermissions(reducePermissions);
    } else {
      setCurrentPermissions([]);
    }
  }, [configurableRole]);

      
  const setModalTitle = (groupId?: string | null) => {
    const titleForOtherRoles = isDefaultGroup
      ? t('Users.modals.configureRoles.defaultRole')
      : t('Users.modals.configureRoles.editRole')

    const localizedRoleName = isDefaultGroup
      ? t(`Users.table.${configurableRole?.name}`)
      : configurableRole?.name
    
    switch(groupId) {
      case 'new':
        return t('Users.modals.configureRoles.creatingNewRole');
      case null:
        return t('Users.modals.configureRoles.title');
      default: 
        return `${titleForOtherRoles}${localizedRoleName}`;
    };
  };

  const sendNewPermissions = async () => {
    setIsLoading(true);
    try {
       if (configurableRole?.role) {
        const updatedRole = await api.updateRole({
          id: configurableRole?.role.id,
          name: configurableRole?.role.name,
          permissions: currentPermissions
        });
        setConfigurableRole({
          ...configurableRole!,
          role: updatedRole.data
        });        

        const updatedDataUser = dataUserTable.map(el => el?.role?.id === updatedRole.data.id 
          ? { ...el, role: { ...el.role, permissions: updatedRole.data.permissions } }
          : el
        );
        
        dispatch(setUserAndData({ dataUserTable: updatedDataUser, users: users }));
        dispatch(setGroups(groups.map(group => group.role?.id === updatedRole.data.id
          ? { ...group, role: { ...group.role, permissions: updatedRole.data.permissions } }
          : group
        )));
        message.success(t('Users.modals.configureRoles.successUpdate'));
       }
    } catch (e) {
      message.error(t('Users.modals.configureRoles.errorUpdate'));
    } finally {
      setIsLoading(false);
    }
  };  

  const disableOkButton = () => {
    const sortedRolePermissions = configurableRole?.role?.permissions && [...configurableRole?.role?.permissions].sort().join(',');
    const isTheSamePermissions = [...currentPermissions].sort().join(',') === sortedRolePermissions;
    return configurableRole?.id === 'new'
      ? false
      : ((isTheSamePermissions || isDefaultGroup)
        ? true
        : false
      )
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      cancelText={t('Documents.management.cancel')}
      title={setModalTitle(configurableRole?.id || null)}
      okText={configurableRole?.id === 'new' ? t('Users.modals.configureRoles.create') : t('Users.modals.configureRoles.saveChanges')}
      confirmLoading={isLoading}
      okButtonProps={{
        disabled: disableOkButton(),
        className: isDefaultGroup || !configurableRole?.id ? classes.displayNone : 'null'
      }}
      onOk={configurableRole?.id === 'new' ? form.submit : sendNewPermissions}
      className={classes.configureModal}
      centered
    >
      <div className={classes.configureWrap}>
        <GroupsList
          setConfigurableRole={setConfigurableRole}
          configurableRole={configurableRole}
        />

        <ConfigurePermissions
          form={form}
          setCurrentPermissions={setCurrentPermissions}
          currentPermissions={currentPermissions}
          configurableRole={configurableRole}
          setConfigurableRole={setConfigurableRole}
          setIsLoading={setIsLoading}
          setIsOpenPermissionsModal={setIsOpenPermissionsModal}
        />
      </div>

      {isLoading && <UploadingComponent />}
    </Modal>
  )
}
