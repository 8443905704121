import React from 'react';
import classes from './OrganizationEmploees.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchUsersOfOrganization, setNewEmployeeRole } from 'store/reducers/organizationCreator';
import RowLoader from 'components/Sceletons/RowLoader';
import { setOrganizationEmployees } from 'store/slices/organization';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import EmployeeCard from './EmployeeCard';
import { IEmployee } from 'interfaces';
import api from 'api';
import { ConfirmationModal, EmployeeInfoModal } from 'components/Modals';

type  OrganizationEmploeesProps = {
  setIsInviteEmployeesOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrganizationEmploees = ({setIsInviteEmployeesOpen}: OrganizationEmploeesProps) => {
  const [isOpenEmployeeDelete, setIsOpenEmployeeDelete] = React.useState(false);
  const [isOpenEmployeeInfo, setIsOpenEmployeeInfo] = React.useState(false);
  const [isDeletingEmployee, setIsDeletingEmployee] = React.useState(false);
  const [selectedEmployee, setSelectedEmployee] = React.useState<IEmployee | null>(null);

  const { organization, organizationEmployees, isLoadingOrganizationEmployees } =  useAppSelector((state) => state.organization);
  const { userData } =  useAppSelector((state) => state.userData);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    organization && dispatch(fetchUsersOfOrganization(organization?.id!));
  }, [organization]);
  
  const deleteEmployeeHandle = (employee: IEmployee) => {
    setIsOpenEmployeeDelete(true);
    setSelectedEmployee(employee);
  }

  const closeModal = () => {
    setIsOpenEmployeeDelete(false);
    setIsOpenEmployeeInfo(false);
    setSelectedEmployee(null);
  }

  const handleOpenEmployeeInfo = (employee: IEmployee) => {
    setIsOpenEmployeeInfo(true);
    setSelectedEmployee(employee);
  }

  const onDeleteSubmit = async () => {
    setIsDeletingEmployee(true);
    const userFullName = `${selectedEmployee?.first_name} ${selectedEmployee?.last_name}`;

    try {
      selectedEmployee && await api.deleteEmployee(selectedEmployee?.user_id);
      message.success(
        `${t('Organizations.exclude.successBefore')} "${userFullName}"
        ${t('Organizations.exclude.successAfter')}`, 10
      );
      dispatch(setOrganizationEmployees(organizationEmployees.filter(employee => employee.user_id !== selectedEmployee?.user_id)));
    } catch (err) {
      message.error(
        `${t('Documents.error.deleteNote.before')} "${userFullName}"
        ${t('Documents.error.deleteNote.after')}`, 10
      );
    } finally {
      setIsOpenEmployeeDelete(false);
      setIsDeletingEmployee(false);
      setSelectedEmployee(null);
    }
  };

  const changeRole = async (employee: IEmployee, role_name: string) => {    
    const responce = await dispatch(setNewEmployeeRole({employee, role_name}))
    const localizedRoleName = t(`Organizations.invite.${role_name}`);

    if (responce.type.split('/').reverse()[0] === 'rejected') {
      message.error(`${t('Organizations.errorChangeRole')} '${localizedRoleName}'`);      
    } else {
      message.success(`${t('Organizations.successChangeRole')} '${localizedRoleName}'`);
    }
  };

  return (
    <div className={classes.wrapUsersSegment}>
      <div className={classes.segmentTitle}>
        <div>{t('Organizations.listOfEmployees')}</div>

        <div className={classes.actions}>
          <div className={classes.count}>
            {t('Organizations.allMembers')}:
            <span style={{padding: '0 0 0 3px'}}>
              {isLoadingOrganizationEmployees 
                ? <RowLoader padding={'3px 0 0 0'} width={12} height={16} />
                : organizationEmployees.length
              }
            </span>
          </div>

          {organization?.permission?.can_invite_organization_users && (
              <Button
              icon={<PlusOutlined />}
              type='primary'
              onClick={() => setIsInviteEmployeesOpen(true)}
            >
              {t('Organizations.addUser')}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.wrapEmployees}>
        {
          isLoadingOrganizationEmployees
            ? (<>
              <RowLoader padding={'8px 0 0 15px'} width={800} height={50} />
              <RowLoader padding={'8px 0 0 15px'} width={800} height={50} />
              <RowLoader padding={'8px 0 0 15px'} width={800} height={50} />
            </>)
            : organizationEmployees.map(employee => (
              <div key={employee.user_id}>
                <EmployeeCard
                  employee={employee}
                  deleteEmployeeHandle={deleteEmployeeHandle}
                  changeRole={changeRole}
                  orgOwner={organization?.owner_id!}
                  handleOpenEmployeeInfo={handleOpenEmployeeInfo}
                  permission={organization?.permission}
                  userId={userData?.id}
                />
              </div>
          ))
        }
      </div>

      {isOpenEmployeeDelete && (
        <ConfirmationModal
          isOpen={isOpenEmployeeDelete}
          onCancel={closeModal}
          isLoading={isDeletingEmployee}
          submit={onDeleteSubmit}
          text={`
            ${t('Organizations.exclude.textBefore')}
            "${`${selectedEmployee?.first_name} ${selectedEmployee?.last_name}`}" 
            ${t('Organizations.exclude.textAfter')}
          `}
          title={t('Organizations.exclude.title')}
          okText={t('Organizations.exclude.button')}
        />
      )}

      {
        isOpenEmployeeInfo && (
          <EmployeeInfoModal
            open={isOpenEmployeeInfo}
            onCancel={closeModal}
            selectedEmployee={selectedEmployee!}
          />
        )
      }
    </div>
  );
}

export default OrganizationEmploees;
