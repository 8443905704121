import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import dayjs from 'dayjs'
import { IDocument } from 'interfaces';

const fetchAllNotes = createAsyncThunk('notes/fetchAll', async (payload: IDocument, thunkApi) => {
  try {
    const response = payload.version_count && payload.version_count > 1
      ? await api.getNotesOfVersions({document_id: payload.id})
      : await api.getDocumentNotes({document_id: payload.id});
      
    const offset = new Date().getTimezoneOffset() * 60 * 1000;    
    return response.data.map((note, i) => {
      const inSeconds = dayjs(note.timestamp, 'DD.MM.YYYY, hh:mm:ss').valueOf();      
      const rightTime = dayjs(inSeconds - offset);      
      return {
        ...note,
        timestamp: rightTime.format('DD.MM.YYYY, HH:mm:ss'),
        timestamp_in_seconds: inSeconds
      }
    }).sort((a,b) => a.timestamp_in_seconds - b.timestamp_in_seconds);
  } catch (err) {    
    return thunkApi.rejectWithValue(`Failed to load notes of №${payload} document`);
  }
});

export { fetchAllNotes };
