import { InviteStatusEnum } from './IInvitations';

export default interface INotification {
  status: number;
  id: string;
  type: NotificationTypeEnum;
  message?: string;
  user?: {
    email: string;
    id: string;
    name: string;
  };
  details: Record<string, string> & {
    invite_id?: string;
    room_id: string;
    send_by: string;
    user_email: string;
    group_name: string;
    room_name: string;
    invite_status?: InviteStatusEnum;
  };
  created_at: string;
  link?: string;
}

export enum NotificationTypeEnum {
  ADD_NOTE = 'add_note',
  AGREEMENT_INITIATED = 'agreement_initiated',
  CANCELING_THE_SIGNING = 'canceling_the_signing',
  CHANGE_STATUS = 'change_status',
  DECLINE_AGREEMENT = 'decline_agreement',
  DELETE_AGREEMENT = 'delete_agreement',
  DELETE_FILE = 'delete_file',
  DELETE_FILE_CONFIDENTIAL = 'delete_file_confidential',
  DELETE_ROOM = 'delete_room',
  DOWNLOAD_CONF_FILE_AS_PDF = 'download_conf_file_as_pdf',
  DOWNLOAD_FILE = 'download_file',
  DOWNLOAD_FILE_AS_PDF = 'download_file_as_pdf',
  DOWNLOAD_FILE_CONFIDENTIAL = 'download_file_confidential',
  DOWNLOAD_PDF_DOCUMENT = 'download_pdf_document',
  FINALIZATION_OF_SIGNATURE = 'finalization_of_signature',
  FINALIZE_AGREEMENT = 'finalize_agreement',
  INITIATION_OF_SIGNING = 'initiation_of_signing',
  INVITE = 'invite',
  MOVING_A_CONF_DOCUMENT_TO = 'moving_a_conf_document_to',
  MOVING_A_DOCUMENT_TO = 'moving_a_document_to',
  NDA_ENABLE = 'nda_enable',
  NEW_MESSAGE = 'new_message',
  REJECTION_SIGNING = 'rejection_signing',
  ROOM_CHAT = 'room_chat',
  SIGNING_OF_DOCUMENT = 'signing_of_document',
  UPDATE_USER_PERMISSIONS = 'update_user_permissions',
  UPLOAD_DOCUMENT = 'upload_document',
  UPLOAD_DOCUMENT_CONFIDENT = 'upload_document_confident',
  UPLOAD_DOCUMENT_VERSION = 'upload_document_version',
  UPLOAD_DOCUMENT_VERSION_CONFIDENTIAL = 'upload_document_version_confidential',
}
