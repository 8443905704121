import { requestTemplate } from 'api';
import { INotes } from 'interfaces';

type SimpleResponse = {
  status: boolean;
  message: string;
};

//NOTES
const getDocumentNotes = (
  payload: {document_id: string}
) => requestTemplate.get<INotes[]>(`/notes/?document_id=${payload.document_id}`);
const deleteNote = (id: string) => requestTemplate.delete<SimpleResponse>(`/notes/?note_id=${id}`);

const createNotes = (
  payload: { text: string, document_id: string, room_id: string, details?: { note_text: string } }
) => requestTemplate.post<INotes>(`/notes/?action=create&id=${payload.room_id}&entity=${payload.document_id}`, payload);

const shareNote = (
  payload: { note_id: string, visible_to: string[] }
) => requestTemplate.post<{message: string, success: boolean}>(`/notes/share/`, payload);

const notes = {
  getDocumentNotes,
  deleteNote,
  createNotes,
  shareNote,
};

export default notes;
