import getDateWithTimeZone from "helpers/getDateWithTimeZone";
import { IResearchResponce, IUserData } from "interfaces";
import { TFunction } from 'i18next';
import { ICompareDataType } from "../interfaces";

const infoKeys = ['name', 'owner_name', 'downloaded_at', 'initiator_name', 'document_id', 'token', 'researched_at', 'version_count'];
const dateKeys = ['downloaded_at', 'created_at', 'modified_at', 'uploaded_at', 'researched_at', 'opened_at'];
const userKeys = ['owner', 'initiator'];
const compareKeys = ['name', 'description', 'extension', 'size', 'author', 'created_at', 'modified_at', 'version', 'room_id', 'room_name', 'uploaded_at', 'opened_at', 'ip', 'browser_version' ]

const prepareDocumentInfo = (documentData: IResearchResponce, t: TFunction, userData: IUserData) => {
  const preparedInfo: any = {};  

  for (let value in documentData) {
    preparedInfo[value] = {};
    const currentObject = documentData[value as keyof typeof documentData];

    for (let key in currentObject) {
      if (dateKeys.includes(key)) {
        preparedInfo[value][key] = currentObject[key as keyof typeof currentObject] && getDateWithTimeZone(currentObject[key as keyof typeof currentObject], userData.date_format);
      } else if (userKeys.includes(key)) {
        preparedInfo[value][`${key}_name`] = currentObject[key as keyof typeof currentObject].name;
      } else {
        preparedInfo[value][key] = currentObject[key as keyof typeof currentObject];
      };

    };
  };

  const infoData: ICompareDataType[] = [];
  const infoCompare: ICompareDataType[] = [];

  const addRowToInfoData = (key: string) => {
    infoData.push({
      key: t(`Report.info.${key}`),
      current: preparedInfo.current[key as keyof typeof preparedInfo.current] || 0,
      previous: preparedInfo.previous[key as keyof typeof preparedInfo.previous] || 0
    });
  };

  const addRowToInfoCompare = (key: string) => {
    infoCompare.push({
      key: t(`Report.info.${key}`),
      current: preparedInfo.current[key as keyof typeof preparedInfo.current],
      previous: preparedInfo.previous[key as keyof typeof preparedInfo.previous]
    });
  };

  infoKeys.forEach(key => {
    addRowToInfoData(key);
  });

  compareKeys.forEach(key => {
    addRowToInfoCompare(key);
  });

  infoData.push({
    key: t(`Report.info.current_user`),
    current: `${userData.first_name} ${userData.last_name}`,
    previous: `${userData.first_name} ${userData.last_name}`,
  })
  
  return {
    infoData,
    infoCompare
  };
}

export default prepareDocumentInfo
